import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { Form } from "react-bootstrap"
import { connect } from "react-redux"
import _ from "lodash"
import {
  deleteProduct,
  setProductQuantity,
  setCreditNoteProductDiscount,
  setCreditNoteProductDiscountType,
  setProductIncludeSerialNumbers,
  setInvoiceWarrantyTime,
} from "../../state/actions/invoice"
import {
  setUpdatedCreditNoteProducts,
  deleteCreditNoteProduct,
  setCreditNoteProductQuantity,
  setCreditNoteProductIncludeSerialNumbers,
} from "../../state/actions/creditNote"
import { formatNumber, getTotal, vatApplicable } from "../../state/utils"
import SerialNumberModalForCreditNote from "./serialNumberModal"

// Styles
import styles from "../../styles/components/table.module.scss"
import { FaTimes } from "react-icons/fa"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const ProductTable = ({
  document,
  invoice,

  creditNoteProducts,
  creditNoteInvoiceProducts,

  deleteCreditNoteProduct,

  handleCreditNoteChangeDiscount,
  handleCreditNoteChangeDiscountType,

  handleCreditNoteIncludeSerialNumbers,
  handleCreditNoteChangeQuantity,
  setUpdatedCreditNoteProducts,
  updatedCreditNoteProducts,
}) => {
  const isVatApplicable = vatApplicable(invoice.date)

  const [products, setProducts] = useState([])

  useEffect(() => {
    if (document === "creditNote") {
      const updatedProducts = creditNoteProducts.map(product => {
        const invoiceProduct = creditNoteInvoiceProducts.find(
          invProd => invProd.sku === product.sku
        )

        return {
          ...product,
          price: invoiceProduct?.price || product.price,
          discount: invoiceProduct?.discount || product.discount,
          discountType: invoiceProduct?.discountType || product.discountType,
        }
      })
      console.log(updatedProducts)
      setProducts(updatedProducts)
      setUpdatedCreditNoteProducts(updatedProducts)
    }
  }, [creditNoteProducts, creditNoteInvoiceProducts])

  const calculateTotalPrice = (price, qty, discount, discountType) => {
    const discountAmount =
      discountType === "LKR"
        ? discount * qty || 0
        : (price * qty * discount || 0) / 100
    if (isNaN(price) || isNaN(qty)) {
      return `Rs. 0`
    } else {
      return `Rs. ${formatNumber(price * qty - discountAmount)}`
    }
  }

  const classes = useStyles()
  return (
    <>
      <TableContainer component={Paper}>
        {products && products.length > 0 && (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Product Description</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Country Of Origin</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Serial Numbers</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {products &&
                _.map(products, (product, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{product.sku}</TableCell>
                    <TableCell>{product.productName}</TableCell>

                    <TableCell>{product.productDescription || "N/A"}</TableCell>

                    {/* <TableCell align="right">{product.category}</TableCell> */}
                    <TableCell>{product.manufacturer}</TableCell>

                    <TableCell>{product.countryOfOrigin}</TableCell>
                    <TableCell>{formatNumber(product.price)}</TableCell>
                    <TableCell>
                      <input
                        value={product.quantity}
                        type="number"
                        onChange={e => {
                          const newQuantity = parseFloat(e.target.value)
                          if (newQuantity < 1) return // Prevent setting a quantity less than 1
                          handleCreditNoteChangeQuantity(
                            product.id,
                            newQuantity
                          )
                        }}
                        style={{ width: "60px" }}
                      />
                    </TableCell>

                    <TableCell>
                      <div>
                        <input
                          value={product.discount}
                          type="number"
                          onChange={e => {
                            const newDiscount = parseFloat(e.target.value)
                            if (isNaN(newDiscount) || newDiscount < 0) {
                              console.error(
                                "Invalid discount: must be a non-negative number"
                              )
                              return
                            }
                            handleCreditNoteChangeDiscount(
                              product.id,
                              newDiscount
                            )
                          }}
                        />
                        <select
                          onChange={e => {
                            handleCreditNoteChangeDiscountType(
                              product.id,
                              e.target.value
                            )
                          }}
                          value={product.discountType}
                        >
                          <option value="%">%</option>
                          <option value="LKR">LKR</option>
                        </select>
                      </div>
                    </TableCell>

                    <TableCell>
                      {calculateTotalPrice(
                        product.price,
                        product.quantity,
                        product.discount,
                        product.discountType
                      )}
                    </TableCell>

                    {document === "creditNote" && (
                      <TableCell>
                        <div className={styles.serialNumberCheckbox}>
                          <Form.Group controlId="serailCheckbox">
                            <Form.Check
                              type="checkbox"
                              label="Include Serial Numbers"
                              value={product.includeSerialNumbers}
                              onChange={() =>
                                handleCreditNoteIncludeSerialNumbers(
                                  product.id,
                                  !product.includeSerialNumbers
                                )
                              }
                            />
                          </Form.Group>
                          {product && product.includeSerialNumbers && (
                            <>
                              <p className="mt-2 mb-1">
                                Count:{" "}
                                {product &&
                                  product?.serialNumbersInCreditNote?.length}
                              </p>
                              <SerialNumberModalForCreditNote
                                id={product.id}
                                documentType={document}
                              />
                            </>
                          )}
                        </div>
                      </TableCell>
                    )}
                    <TableCell>
                      <FaTimes
                        color="red"
                        style={{ cursor: "pointer", fontSize: "12" }}
                        onClick={() => {
                          deleteCreditNoteProduct(product.id)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}

        {products && products.length > 0 && (
          <>
            {isVatApplicable && (
              <div className={styles.total2}>
                <p>
                  Subtotal:
                  <span className={styles.amount}>
                    Rs. {products && formatNumber(getTotal(products))}
                  </span>
                </p>
              </div>
            )}

            {
              // if year is 2024, add 18% VAT
              <>
                {/* 18% Vat */}
                {isVatApplicable && (
                  <div className={styles.total2}>
                    <p>
                      18% VAT:
                      <span className={styles.amount}>
                        Rs.{" "}
                        {products && formatNumber(getTotal(products) * 0.18)}
                      </span>
                    </p>
                  </div>
                )}

                <div className={styles.total}>
                  {isVatApplicable ? (
                    <p>
                      Total:
                      <span className={styles.amount}>
                        Rs.
                        {products && formatNumber(getTotal(products) * 1.18)}
                      </span>
                    </p>
                  ) : (
                    <p>
                      Total:
                      <span className={styles.amount}>
                        Rs. {products && formatNumber(getTotal(products))}
                      </span>
                    </p>
                  )}
                </div>
              </>
            }
          </>
        )}
      </TableContainer>
      {products && products.length === 0 && (
        <div>
          <p>Please add a product to get started</p>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    invoiceProducts: state.invoice.invoiceProducts,
    quoteProducts: state.quotation.newQuotation.quotationProducts,
    creditNoteProducts: state?.creditNotes?.newCreditNote?.creditNoteProducts,
    invoice: state?.invoice,
    creditNoteInvoiceProducts: state?.creditNotes?.invoice?.invoiceProducts,
    updatedCreditNoteProducts:
      state?.creditNotes?.newCreditNote?.updatedCreditNoteProducts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteInvoiceProduct: data => dispatch(deleteProduct(data)),
    handleInvoiceChangeQuantity: (id, quantity) =>
      dispatch(setProductQuantity(id, quantity)),

    handleCreditNoteChangeDiscount: (id, discount) =>
      dispatch(setCreditNoteProductDiscount(id, discount)),
    handleCreditNoteChangeDiscountType: (id, discountType) =>
      dispatch(setCreditNoteProductDiscountType(id, discountType)),

    handleInvoiceIncludeSerialNumbers: (id, includeSerialNumbers) =>
      dispatch(setProductIncludeSerialNumbers(id, includeSerialNumbers)),
    handleInvoiceWarrantyTime: (id, newWarranty) =>
      dispatch(setInvoiceWarrantyTime(id, newWarranty)),

    deleteCreditNoteProduct: data => dispatch(deleteCreditNoteProduct(data)),
    handleCreditNoteIncludeSerialNumbers: (id, includeSerialNumbers) =>
      dispatch(
        setCreditNoteProductIncludeSerialNumbers(id, includeSerialNumbers)
      ),
    handleCreditNoteChangeQuantity: (id, quantity) =>
      dispatch(setCreditNoteProductQuantity(id, quantity)),

    setUpdatedCreditNoteProducts: products =>
      dispatch(setUpdatedCreditNoteProducts(products)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTable)
