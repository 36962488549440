import React, { useEffect } from "react"
import { connect } from "react-redux"
import DownloadBrochure from "./downloadBrochure"
import styles from "../styles/components/table.module.scss"

const ProductCatalog = ({ products }) => {
  const hiddenSkus = [
    "INVSOLGDW5KXXXX",
    "INVGRWOFG5KXXXX",
    "INVSOLS61P1000K",
    "INVSOL1P4000XXX",
    "INVSOL1P5000XXX",
    "INVSOL1PHY5KXXX",
    "INVSOL3P1700XXX",
    "INVSOL3P20KXXXX",
    "INVSOL3P6000XXX",
    "INVSOLS5EH1P5KL",
    "PVMPHSMH400XXXX",
    "PVMPHSMH450XXXX",
    "PVMPHSP540XXXXX",
    "CABSHYDC4MMBXXX",
    "CABSHYDC4MMRXXX",

    // Hidden on 19 Nov
    "INVSOLS5EO1P5KP",
    "INVSOLS6EH1P5KL",
    "INVSOLS6EH1P6KX",
    "INVDEYE1P3KXXXX",
    "INVDEYE1P5KXXXX",
    "INVDEYE3P5KXXXX",
    "INVDEYE3P8KXXXX",
    "INVDEYE3P10KXXXX",
    "PVMJA570XXXXXXX",
    "PVMPHSP580XXXXX",
    "PVMJA595XXXXXXX",
    "ELAORGMCB2P4XXX",
    "ELAORGMCB4P4XXX",
    "HWALCLAWMS1XXXX",
    "HWAACNBOLT10XXX",
    "HWALCLARN3XXXXX",
    "HWALCLARN4XXXXX",
    "HWAROOFINGN2INX",
    "HWALCLARN45XXXX",
    "HWASLNBD40XXXXX",
    "HWATNXFXHG140XX",
    "HWAZLNGIC40XXXX",
    "HWAZLNGIC60XXXX",
    "HWATNXFXHG4XXXX",
    "HWASLNLB40XXXXX",
    "HWATNXFXHG2XXXX",
    "HWATNXFXHP15XXX",
    "HWATNXFXHP2XXXX",
    "HWALCLASLONGLXX",
    "HWASLNPP40XXXXX",
    "HWASLNRDTEE4020",
    "HWASLNRD4032XXX",
    "HWASLNRDTEE4032",
    "HWASLNSK40XXXXX",
    "HWASLNTSK40XXXX",
    "HWASLNTSK403XXX",
    "HWALCLWP8XXXXXX",
    "HWALCLWSR10XXXX",
    "CABKLC5C10MMXXX",
    "CABKLC5C6MMXXXX",
    "CABKLC5C16MMXXX",
    "BATDYNWIREKXXXX",
    "BATDEX12V35AHXX",
    "BATDYN4X8KXXXXX",

    // Hidden on 10 December
    "PVMTUNG550XXXXX",

    // Hidden on 17 th Jan 2025
    "PVMJA550XXXXXXX",
    "PVMPHSP550XXXXX",
  ]

  return (
    <div className={styles.wrapper}>
      <DownloadBrochure
        products={products
          .filter(
            product =>
              product.deleted === false || product.deleted === undefined
          )
          .filter(product => !hiddenSkus.includes(product.sku))}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    products: state.product.products,
  }
}

export default connect(mapStateToProps)(ProductCatalog)
