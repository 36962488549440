import React, { useState, useEffect, useRef } from "react"
import moment from "moment"
import { connect } from "react-redux"
import { Table, Button, Modal, Form } from "react-bootstrap"
import { FaWindowClose } from "react-icons/fa"
import { confirmAlert } from "react-confirm-alert"
import BackButton from "../backButton"
import logo from "../../images/logo.png"
import { formatNumber } from "../../state/utils"
import {
  updateDispatchDate,
  updateDeliveryMethod,
  updateDeliveryNoteStatus,
  setItemPreparationStarted,
} from "../../state/actions/deliveryNotes"
import DeliveryNotePdf from "./deliverNotePdf"
import WarrentyCardPdf from "./WarrentyCardPdf"

import styles from "../../styles/components/deliveryNotes/viewDeliveryNote.module.scss"
import Loading from "../loading"

const ViewDeliveryNote = ({
  deliveryNote,
  invoices,
  products,
  customers,
  senderAddress,
  senderCity,
  senderTel,
  updateDispatchDate,
  dnDispatchDate,
  updateDeliveryMethod,
  dnDeliveryMethod,
  updateDeliveryNoteStatus,
  dnStatus,
  itemPreparationStartedTime,
  warehousePersonName,
  deliveredTime,
  setItemPreparationStarted,
  itemPreparationStarted,
}) => {
  const selectRef = useRef(null)

  const [loading, setLoading] = useState(true) // Add a loading state
  const [invoice, setInvoice] = useState({})
  const [customer, setCustomer] = useState({})
  const [invoiceProducts, setInvoiceProducts] = useState([])
  const [
    localItemPreparationStartedTime,
    setItemPreparationStartedTime,
  ] = useState(itemPreparationStartedTime)

  const formattedDeliveredTime = new Date(deliveredTime).toLocaleString(
    "en-US",
    {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    }
  )

  useEffect(() => {
    if (invoices.length > 0 && deliveryNote && products.length > 0) {
      // Check if all required data has loaded
      const foundInvoice = invoices.find(
        invoice => invoice.invoiceNumber === deliveryNote.invoiceNumber
      )
      if (foundInvoice) {
        setInvoice(foundInvoice)
        setCustomer(
          customers.find(
            customer => customer.code === deliveryNote.customerCode
          )
        )
        setInvoiceProducts(
          foundInvoice.invoiceProducts.map(item => {
            return {
              ...products.find(product => product.sku === item.sku),
              sku: item.sku,
              quantity: item.quantity,
              serialNumbersInInvoice: item.serialNumbers,
              newWarranty: item.newWarranty,
            }
          })
        )
        setLoading(false)
      }
    }
  }, [invoices, deliveryNote, products, customers])

  const [showDispatchModal, setShowDispatchModal] = useState(false)
  const [showDeliveryModal, setShowDeliveryModal] = useState(false)

  const [dispatchDate, setDispatchDate] = useState(
    deliveryNote.dispatchDate
      ? moment(deliveryNote.dispatchDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
  )

  const [deliveryMethod, setDeliveryMethod] = useState("")

  const handleDispatchDateSave = e => {
    e.preventDefault()

    updateDispatchDate(deliveryNote._id, dispatchDate)
    setShowDispatchModal(false)
  }

  const handleDeliveryMethodSave = e => {
    e.preventDefault()

    updateDeliveryMethod(deliveryNote._id, deliveryMethod)
    setShowDeliveryModal(false)
  }

  const handleCofirmDelivered = () => {
    confirmAlert({
      title: "Confirm Delivered",
      message:
        "Are you sure you want to mark this delivery note as delivered? This action cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            updateDeliveryNoteStatus(deliveryNote._id, "Delivered")
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const handleItemPreparation = () => {
    confirmAlert({
      title: "Item Preparation",
      message: "Are you sure you want to start item preparation?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setItemPreparationStarted(deliveryNote._id, {
              itemPreparationStarted: true,
            })
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const dnData = {
    senderAddress,
    senderCity,
    senderTel,
    date: moment(new Date(invoice.date)).format("DD-MM-YYYY"),
    invoiceNumber: invoice.invoiceNumber,
    deliveryNoteNumber: deliveryNote.deliveryNoteNumber,
    customer,
    dispatchDate: deliveryNote.dispatchDate
      ? moment(new Date(dnDispatchDate)).format("DD-MM-YYYY")
      : "",
    deliveryMethod: dnDeliveryMethod,
    products: invoiceProducts,
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className="mt-4">
        <h1>
          {deliveryNote.deliveryNoteNumber}{" "}
          {dnStatus === "Cancelled" && (
            <span className="text-danger">(Cancelled)</span>
          )}
          {dnStatus === "Delivered" && (
            <span className="text-success">(Delivered)</span>
          )}
        </h1>

        {itemPreparationStarted && localItemPreparationStartedTime && (
          <div>
            Item Preparation Started Time -{" "}
            {new Date(localItemPreparationStartedTime).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            })}
          </div>
        )}

        {deliveredTime && <div>Delivered Time - {formattedDeliveredTime}</div>}

        <BackButton to="/app/delivery-notes" />

        {/* Action Buttons */}
        {dnStatus !== "Cancelled" && dnStatus !== "Delivered" && (
          <div className="action-buttons mt-5">
            {!itemPreparationStarted && (
              <Button
                variant="success"
                className="mr-4"
                onClick={handleItemPreparation}
              >
                Item Preperation
              </Button>
            )}
            {itemPreparationStarted && (
              <Button
                variant="success"
                className="mr-4"
                onClick={handleCofirmDelivered}
              >
                Confirm Delivered
              </Button>
            )}

            <Button
              variant="light"
              className="mr-4"
              onClick={() => setShowDispatchModal(true)}
            >
              Set Dispatch Date
            </Button>
            <Button
              variant="light"
              className="mr-4"
              onClick={() => setShowDeliveryModal(true)}
            >
              Set Delivery Method
            </Button>
          </div>
        )}

        {/* Document Preview */}
        <div className={styles.documentPreview}>
          <div className={styles.info}>
            {/* Company Info */}
            <div>
              <div className={styles.logo}>
                <img src={logo} alt="Gunda Power Logo" />
              </div>
              <div className={styles.senderInfo}>
                <p>{senderAddress}</p>
                <p>{senderCity}</p>
                <p>{senderTel}</p>
                <p>info@gundapower.com / www.gundapower.com</p>
              </div>
            </div>

            {/* Document Info */}
            <div className={styles.documentInfo}>
              <h1>Delivery Note</h1>
              <div className={styles.documentRow}>
                <div className={styles.cell1}>Order Date</div>
                <div className={styles.cell2}>
                  {moment(new Date(invoice.date)).format("DD-MM-YYYY")}
                </div>
              </div>
              <div className={styles.documentRow}>
                <div className={styles.cell1}>Invoice No.</div>
                <div className={styles.cell2}>{invoice.invoiceNumber}</div>
              </div>
              <div className={styles.documentRow}>
                <div className={styles.cell1}>Delivery Note No.</div>
                <div className={styles.cell1}>
                  {deliveryNote.deliveryNoteNumber}
                </div>
              </div>
              <div className={styles.documentRow}>
                <div className={styles.cell1}>Customer ID</div>
                <div className={styles.cell1}>{deliveryNote.customerCode}</div>
              </div>
              <div className={styles.documentRow}>
                <div className={styles.cell1}>Dispatch Date</div>
                <div className={styles.cell1}>
                  {dnDispatchDate
                    ? moment(new Date(dnDispatchDate)).format("DD-MM-YYYY")
                    : ""}
                </div>
              </div>
              <div className={styles.documentRow}>
                <div className={styles.cell1}>Delivery Method</div>
                <div className={styles.cell1}>{dnDeliveryMethod}</div>
              </div>
            </div>
          </div>

          <div className={styles.info}>
            {/* Customer Info */}
            <div className={styles.customerInfo}>
              <span>Bill To:</span>
              <div className={styles.customerDiv}>
                <p>{customer?.name}</p>
                <p>{customer?.address}</p>
                <p>{customer?.telephone}</p>
                <p>{customer?.email}</p>
              </div>
            </div>

            {/* Delivery Details */}
            <div className={styles.deliveryDetails}>
              <span>Deliver To:</span>
              <div className={styles.customerDiv}>
                <p>[Name]</p>
                <p>[Company Name]</p>
                <p>[Street Address]</p>
              </div>
            </div>
          </div>

          {/* Product Table */}
          <div className={styles.itemTable}>
            <Table responsive>
              <thead>
                <tr>
                  <th colSpan={4} style={{ border: "none" }}></th>
                  <th colSpan={2} style={{ border: "1px solid #000" }}>
                    Customer to complete
                  </th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>Item Description</th>
                  <th>Image</th>
                  <th>Ordered</th>
                  <th>Received</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {invoiceProducts.map((product, idx) => (
                  <tr key={product.sku}>
                    <td>{idx + 1}</td>
                    <td>
                      <p>{product.productName}</p>
                      {product.productDescription !== "N/A" && (
                        <p>{product.productDescription}</p>
                      )}
                      <p>{product.sku}</p>
                    </td>
                    <td>
                      {product.productImages && product.productImages[0] ? (
                        <img
                          src={product.productImages[0].fields.file.url}
                          className={styles.itemImg}
                          alt={`${product.productName}`}
                        />
                      ) : (
                        <p>N/A</p>
                      )}
                    </td>
                    <td>{product.quantity}</td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Signature */}
          <div className={styles.signatureRow}>
            <table className={styles.signatureTable}>
              <tbody>
                <tr>
                  <td>Issued Name</td>
                  <td></td>
                </tr>
                <tr>
                  <td>NIC No.</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Signature</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <table className={styles.signatureTable}>
              <tbody>
                <tr>
                  <td>Receiver's Name</td>
                  <td></td>
                </tr>
                <tr>
                  <td>NIC No.</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Signature</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Terms & Conditions */}
          <div className="border border-dark mt-4 p-3 text-center">
            <h5>Terms &amp; Conditions</h5>
            <p>
              Upon receiving the goods, any product damages or shortages should
              be reported immediately to Gunda Power (Pvt) Ltd.
            </p>
          </div>
        </div>

        {/* Serial Numbers */}
        {invoiceProducts.some(
          product => product.serialNumbersInInvoice.length !== 0
        ) && (
          <div className="mt-3" style={{ fontSize: "10px" }}>
            <h3>Serial Numbers</h3>
            <Table responsive>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Serial Numbers</th>
                </tr>
              </thead>
              <tbody>
                {invoiceProducts.map((product, idx) => {
                  if (product.serialNumbersInInvoice.length === 0) return

                  return (
                    <tr key={product.sku}>
                      <td>
                        <p className="m-0">{product.productName}</p>
                        {product.productDescription !== "N/A" && (
                          <p className="m-0">{product.productDescription}</p>
                        )}
                        <p className="m-0">{product.sku}</p>
                      </td>
                      <td>
                        {product.serialNumbersInInvoice.map(serial => (
                          <span
                            key={serial.main}
                            className="mr-3"
                            style={{ backgroundColor: "#eee" }}
                          >
                            {serial.main}{" "}
                            {product.category === "Solar Inverter" &&
                              serial.complimentary &&
                              `(WiFi Stick Serial: ${serial.complimentary}) `}
                          </span>
                        ))}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        )}
      </div>

      {/* PDF Download */}
      {dnStatus !== "Cancelled" && <DeliveryNotePdf data={dnData} />}
      {dnStatus === "Delivered" && <WarrentyCardPdf data={dnData} />}

      {/* Dispatch Date Modal */}
      <Modal
        show={showDispatchModal}
        onHide={() => setShowDispatchModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Set Dispatch Date</Modal.Title>
          <Button variant="danger" onClick={() => setShowDispatchModal(false)}>
            <FaWindowClose />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <Form onSubmit={e => handleDispatchDateSave(e)}>
              <Form.Group>
                <Form.Label>Dispatch Date</Form.Label>
                <Form.Control
                  type="date"
                  value={dispatchDate}
                  onChange={e => setDispatchDate(e.target.value)}
                />
                <Button variant="primary" className="mt-3" type="submit">
                  Save
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {/* Deliver Method Modal */}
      <Modal
        show={showDeliveryModal}
        onHide={() => setShowDeliveryModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Set Delivery Method</Modal.Title>
          <Button variant="danger" onClick={() => setShowDeliveryModal(false)}>
            <FaWindowClose />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <Form onSubmit={e => handleDeliveryMethodSave(e)}>
              <Form.Group>
                <Form.Label>Delivery Method</Form.Label>
                <Form.Control
                  type="text"
                  value={deliveryMethod}
                  onChange={e => setDeliveryMethod(e.target.value)}
                />
                <Button variant="primary" className="mt-3" type="submit">
                  Save
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = (state, props) => {
  return {
    deliveryNote: state.deliveryNotes.deliveryNotes.find(
      dn => dn.deliveryNoteNumber === props.id
    ),
    dnDispatchDate: state.deliveryNotes.deliveryNotes.find(
      dn => dn.deliveryNoteNumber === props.id
    ).dispatchDate,
    dnDeliveryMethod: state.deliveryNotes.deliveryNotes.find(
      dn => dn.deliveryNoteNumber === props.id
    ).deliveryMethod,
    dnStatus: state.deliveryNotes.deliveryNotes.find(
      dn => dn.deliveryNoteNumber === props.id
    ).status,
    itemPreparationStarted: state.deliveryNotes.deliveryNotes.find(
      dn => dn.deliveryNoteNumber === props.id
    ).itemPreparationStarted,
    itemPreparationStartedTime: state.deliveryNotes.deliveryNotes.find(
      dn => dn.deliveryNoteNumber === props.id
    ).itemPreparationStartedTime,
    warehousePersonName: state.deliveryNotes.deliveryNotes.find(
      dn => dn.deliveryNoteNumber === props.id
    ).warehousePersonName,
    deliveredTime: state.deliveryNotes.deliveryNotes.find(
      dn => dn.deliveryNoteNumber === props.id
    ).deliveredTime,

    invoices: state.invoices.invoices,
    products: state.product.products,
    customers: state.customer.customers,
    senderAddress: state.invoice.senderAddress,
    senderCity: state.invoice.senderCity,
    senderTel: state.invoice.senderTel,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateDispatchDate: (id, disptachDate) =>
      dispatch(updateDispatchDate(id, disptachDate)),
    updateDeliveryMethod: (id, deliveryMethod) =>
      dispatch(updateDeliveryMethod(id, deliveryMethod)),
    updateDeliveryNoteStatus: (id, status) =>
      dispatch(updateDeliveryNoteStatus(id, status)),
    setItemPreparationStarted: (id, data) =>
      dispatch(setItemPreparationStarted(id, data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewDeliveryNote)
